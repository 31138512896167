import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ReactComponent as BigQuote } from "../images/big-quote_sim.svg"
import EmailCaptureForm from "../components/EmailCaptureForm"
import Seo from "../components/seo"

import Layout from "../components/layout"

const MagazinePage = ({ data }) => (
  <Layout>
    <Seo
      title="Magazine — Social Impact Magazine"
      keywords={[
        `Social Impact Magazine`,
        `Entrepreneurship Magazine`,
        `Entrepreneur Magazine`,
      ]}
    />
    <main className="main" role="document">
      <section className="about-magazine  [ section  section--neutral ]">
        <div className="container">
          <aside className="row  shell-flex-parent--end-main">
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  hidden--desktop ]">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--neutral ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/entrepreneur-with-hand-tattoo.jpg/" alt="Entrepreneur with hand tattoo" />
                  </div>
                </figure>
              </div>
            </div>
          </aside>
          <aside className="row  about-magazine__content-header  margin-bottom--10">
            <div className="[ column  column--xs-6  column--lg-4 ]">
              <div className="margin-top--20">
                <hgroup className="headlines">
                  <h4 className="[ headline  headline--uppersmall ]  margin-bottom--1">
                    What is this magazine about?
                  </h4>
                  <hr className="[ hr  hr--uppersmall ]  margin-bottom--3" />
                  <h2 className="[ headline  headline--beta ]  margin-bottom--4">
                    Showcasing exceptional people and teaches you to take action
                  </h2>
                </hgroup>
                <div className="[ description  description--hero ]">
                  <p>
                    It’s not the next typical glorifying everything in the
                    startup world magazine. It explores the social aspect as
                    well as the business side, culture and curiosities and
                    offers inspiration and new perspectives. The Social Impact
                    Magazine showcases exceptional people that doing meaningful
                    changes in the world.
                  </p>
                  <p>
                    We take you on a journey and uncover what is behind these
                    ideas, thoughts and products. We will share any bit of
                    knowledge we gained through the interviews with these
                    purpose-driven people. Our hope is to inspire, nourish and
                    support you to pursue your passion and make it a reality.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-6  column--lg-2 ]  hidden--mobile">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--neutral ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/entrepreneur-with-hand-tattoo.jpg/" alt="Entrepreneur with hand tattoo" />
                  </div>
                </figure>
              </div>
            </div>
          </aside>
          <aside className="row  about-magazine__key-content  margin-bottom--10">
            <div className="[ column  column--xs-1  column--sm-3  column--lg-2 ]  position--relative">
              <div className="headlines--alternative">
                <h5 className="[ headline  headline--alternative ]">
                  <span className="headline--alternative__transform">
                    Highlights
                  </span>
                </h5>
              </div>
            </div>
            <div className="[ column  column--xs-5  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top-thicker ]">
                <h3 className="[ headline  headline--gamma-column ]  margin-top--2  margin-bottom--2">
                  Deliver Insights
                </h3>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    We discover new ideas and out-of-the-box thinking. You will
                    hold a highly insightful source of stories, views, and
                    opportunities in your hand that will lead to meaningful
                    changes.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top-thicker ]">
                <h3 className="[ headline  headline--gamma-column ]  margin-top--2  margin-bottom--2">
                  Giving advice
                </h3>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    Contributors to this magazine provide practical advice,
                    skill-share, and knowledge to support you in your journey of
                    social entrepreneurship or when you're at the start of
                    building something powerful from scratch.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ quote  quote--column ]">
                <div className="big-quote  margin-bottom--2">
                  <BigQuote />
                </div>
                <div className="[ description  description--quote ]  margin-bottom--6">
                  <blockquote>
                    Being an entrepreneur, especially in the social impact
                    field, isn’t always as glamorous as it seems, but when
                    everything aligns, you couldn’t ask for a better job.”
                  </blockquote>
                  <span className="quote-author  text--right">
                    — Ben Harris
                  </span>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top-thicker ]">
                <h3 className="[ headline  headline--gamma-column ]  margin-top--2  margin-bottom--2">
                  A more critical view
                </h3>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    We're challenging the status quo, we will questioning the
                    legitimacy and impact of each social enterprise. Our
                    approach comes with honesty and pragmatism as well as with a
                    good sense of humor.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top-thicker ]">
                <h3 className="[ headline  headline--gamma-column ]  margin-top--2  margin-bottom--2">
                  The big mission
                </h3>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    Our mission is getting to know and profile these responsible
                    business leaders and social entrepreneurs: what motivates
                    them, what’s the mindset behind it and what can we learn
                    from it.
                  </p>
                </div>
              </div>
            </div>
          </aside>
          <aside className="row  about-magazine__impressions">
            <div className="[ column  column--xs-2  column--lg-2 ]  shell-flex-child--align-bottom">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--call-to-action ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/woman-leader-approving.jpg/" alt="Woman leader approving" placeholder="blurred" />
                  </div>
                </figure>
              </div>
            </div>
            <div className="[ column  column--xs-4  column--lg-4 ]">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--call-to-action ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/entrepreneur-with-coffee.jpg/" alt="Entrepreneur with coffee" placeholder="blurred" />
                  </div>
                </figure>
              </div>
            </div>
          </aside>
        </div>
      </section>
      <section
        idName="email"
        className="email-updates  [ section  section--call-to-action ]"
      >
        <div className="container">
          <div className="row">
            <div className="[ column  column--xs-6  column--sm-2  column--lg-2 ]">
              <div className="margin-right--2  position--relative">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--call-to-action ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/man-lay-wall.jpg/" alt="Man lay on wall" placeholder="blurred" />
                  </div>
                </figure>
                <div className="headlines--alternative  absolute-margin-top--2  hidden--mobile">
                  <h5 className="[ headline  headline--alternative ]">
                    <span className="headline--alternative__transform">
                      Subscribe
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-6  column--sm-4  column--lg-4 ]  margin-bottom--10">
              <hgroup className="headlines  margin-top--20  margin-bottom--4">
                <h2 className="[ headline  headline--beta ]">
                  A thoughtful weekly newsletter helping you feel inspired, learn new traits, and think from new perspectives
                </h2>
              </hgroup>
              <EmailCaptureForm className="email-updates-form  [ newsletter-signup  newsletter-signup--large ]  width--100  margin-bottom--0" />
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default MagazinePage